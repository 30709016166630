
import { graphql } from "gatsby";
import React from 'react';
import Layout from '../components/layout/Layout';
import { Trans } from 'gatsby-plugin-react-i18next';

const Note = ({note}) => {

  const meta = note.frontmatter;

  return(
    <div className="full-w">
      <div className="px-6 pt-4 pb-8">
        <div className="flex items-center mb-2">
          {
            meta.kind.map((tag, index) => {
              if(tag === 'update')
                return <span key={index} className="inline-block bg-blue-200 rounded-full px-3 py-1 text-xs font-semibold text-blue-700 mr-2"><Trans>updates.tag</Trans></span>
              if(tag === 'feature')
                return <span key={index} className="inline-block bg-green-200 rounded-full px-3 py-1 text-xs font-semibold text-green-700 mr-2"><Trans>features.tag</Trans></span>
              if(tag === 'application')
                return <span key={index} className="inline-block bg-red-200 rounded-full px-3 py-1 text-xs font-semibold text-red-700 mr-2"><Trans>application.tag</Trans></span>
              if(tag === 'website')
                return <span key={index} className="inline-block bg-amber-200 rounded-full px-3 py-1 text-xs font-semibold text-amber-700 mr-2"><Trans>website.tag</Trans></span>
              else
                return false
            })
          }
          <span className="inline-block text-sm text-gray-500 mr-2">{meta.date} by {meta.author}</span>
        </div>
        {/* <div className="font-bold text-2xl mb-2 mt-2">{meta.title}</div> */}
        <div className="" dangerouslySetInnerHTML={{ __html: note.html }} />
      </div>
    </div>
  )
}

const ReleaseNotes = ({
  location,
  data: {
    allMarkdownRemark : { nodes }
  }
}) => {

  return (
    <Layout location={location} >
      <div className="divide-y divide-gray-400">
        {
          nodes.map(node => {
            return <Note key={node.id} note={node}/>
          })
        }
      </div>
    </Layout>
    
  )
};

export default ReleaseNotes;

export const query = graphql`
  query ($language: String!){
    allMarkdownRemark(
      filter: {fields: {sourceInstanceName: {eq: "release-notes"}, langKey: {eq: $language}}, frontmatter: {published: {eq: true}}}
      sort: {fields: frontmatter___version, order: DESC}
    ) {
      nodes {
        frontmatter {
          title
          published
          title
          tag
          version
          kind
          author
          date(formatString: "LL", locale: $language)
        }
        html
      }
    }
    locales: allLocale{
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`